<mat-card class="blog-post-card" [routerLink]="post.route">
  <div class="image-container">
    <img mat-card-image [src]="post.featuredImage" [alt]="post.featuredImageAlt" />
    <div class="categories">
      <ng-container *ngFor="let category of post.categories; trackBy: trackByFn">
        <span class="mat-caption category">{{ category }}</span>
      </ng-container>
    </div>
  </div>
  <div class="content-blog-post-container">
    <mat-card-header>
      <mat-card-subtitle class="mat-caption">
        <ng-content select="[subTitle]"></ng-content>
      </mat-card-subtitle>
      <mat-card-title
        ><h3>{{ post.title }}</h3></mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <p>{{ post.description }}</p>
    </mat-card-content>
    <mat-card-actions>
      <ng-content select="[actions]"></ng-content>
    </mat-card-actions>
    <mat-card-footer>
      <ng-content select="[footer]"></ng-content>
    </mat-card-footer>
  </div>
</mat-card>
