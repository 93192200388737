import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BlogPostCardComponent } from './blog-post-card.component';

@NgModule({
  declarations: [BlogPostCardComponent],
  imports: [CommonModule, RouterModule, MatCardModule, MatIconModule],
  exports: [BlogPostCardComponent],
})
export class BlogPostCardModule {}
